<template>
    <section
        class="LogoWall"
        :class="{ 'LogoWall--four-or-less': slice.items.length <= 4 }"
    >
        <ul class="LogoWall__list">
            <li v-for="item in calculatedItems" class="LogoWall__list-item">
                <SmartImage
                    class="LogoWall__logo"
                    :url="item.image.url"
                    :width="item.image.dimensions.width"
                    :height="item.image.dimensions.height"
                    :with-placeholder="false"
                    :srcs="[{ width: 300 }, { width: 500 }]"
                    positioning="relative"
                />
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.LogoWallSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const calculatedItems = computed(() => {
    return props.slice.items
        .map((item) => {
            if (props.slice.variation === 'default') {
                return {
                    image: item.brand?.data?.client_logo?.url
                        ? item.brand?.data?.client_logo
                        : null,
                }
            } else if (props.slice.variation === 'customImage') {
                return {
                    image: item.image?.url ? item.image : null,
                }
            }
        })
        .filter((item) => {
            return item?.image?.url ? true : false
        })
})
</script>

<style lang="scss">
.TitleTextBlock + .LogoWall {
    margin-top: 4rem;

    @include medium-up {
        margin-top: 7.5rem;
    }
}

.LogoWall {
    grid-column: 2 / span 22;
    margin-top: 8.75rem;

    &:last-child {
        margin-bottom: 3rem;
    }

    @include medium-up {
        margin-top: 11.25rem;
    }
}

.LogoWall--four-or-less {
    .LogoWall__list-item {
        min-width: 160px;
        max-width: 300px;
    }
}

.LogoWall__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
}

.LogoWall__list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 0.94rem);
    padding: 0.75rem 1.25rem;
    position: relative;
    aspect-ratio: 16 / 12;

    @include large-up {
        width: calc(25% - 1.13rem);
        padding: 3.75rem;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black-20);
        transform: scale(0.95);
        opacity: 0;
        transition:
            transform 0.4s 0s ease,
            opacity 0.4s 0s ease;

        @include touchscreen {
            transform: scale(1);
            opacity: 1;
        }
    }

    &:hover {
        .LogoWall__logo {
            opacity: 1;
        }

        &::before {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.LogoWall__logo {
    width: 140px;
    opacity: 0.6;
    transition: opacity 0.5s 0s ease;

    @include touchscreen {
        opacity: 1;
    }
}
</style>
